.mobMenu { 
	top: 0; right: 0; bottom: 0; left: 0; 
	z-index: 99999;
	-webkit-transition: opacity 1.5s ease-in;
	-moz-transition: opacity 1.5s ease-in;
	transition: opacity 1.5s ease-in; 
	pointer-events: none;
	width: 100%;
}
.mobMenu::after { position:absolute; padding:5px; right:0; top:-2em; } 

nav {
    display:flex; flex: 0 0 auto;
    background-color:#333;
    div div a {
        display: inline-block;
        margin: 0; padding: @m @m2 @m;
    }
    
    a, a:visited { padding: @m @m2 @m; color:#ccc; border-bottom:3px solid #333;}
    a:hover { color:#eee; background-color: #666; border-bottom:3px solid #fff; text-decoration: none; }
} 
//
.debug {
    font-size:0.7em; line-height:1.2em;
    z-index:0;
    display:block; //visibility:hidden;
    overflow:hidden;
    box-sizing: padding-box;
    padding: @m2;
    background-color:#ff9;
    top:0; left:-1000px; bottom:0; width:0; height:500px;
    
    position: absolute;
    // height: 0; width: 100%; top: -50px;  right:2em;
    /* Анимация */
    transition: left 0.25s ease 0.75s, width 0.5s ease 0.5s; 
    
    //transition-property: background-color; 
	/* transition-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0); */
	 a, a:visited { color: @link-color; border-bottom: 1px solid @link-color; padding: 0.1em; }
	 code { border-radius:@r; background-color:#ffe; padding: @m @m2; }
	}
a[href*="moder"]:hover + .debug, .debug:hover {
    display:block; //visibility:visible; 
    left: 0; width: 30em;  min-width: 100px; max-width:600px;
    padding: @m2;
    box-shadow: 0 0 1em rgba(0,0,0,0.5);
    a:hover { background-color: @link-color; border-bottom: 1px solid @link-color; color:#fff; }
}
 
// больше телефона
@media (min-width: 720px) {
	nav {
        .mobMenu, .mobMenu:target {
            display: flex; 
            flex-direction: row;
	        align-items: stretch;
            justify-content: space-between;
            margin: 0 @m4; 
            
            pointer-events: auto;
        }
	        
        .mobMenu > .nav-mnu { display: inline-block;}
        .mobMenu:target > .nav-mnu { display: inline-block; }
        .mobMenu .overclose { display:none; }
    // кнопка открывающая меню 
	    &> a.mob { display:none; }
	 }
	 nav .home::after, nav .admin::after  {
	     color:#fff; 
	     content: "//";
	 }
}
// телефон
@media (max-width: 740px){
	nav {
	    height:0;
	    background-color: transparent;
	    overflow:visible;
	    box-shadow: none;
	    
	    .mobMenu { display: none; visibility: hidden; }
	    .mobMenu:target {
	        display: flex;
	        position: fixed;
	        align-items: stretch;
            flex-direction: column;
            justify-content: space-between;
            
	        pointer-events: auto;
	        visibility:visible;
	       }
	    
        .mobMenu > .nav-mnu { display: none; }
        .mobMenu:target > .nav-mnu { display: block; z-index:2;}
        
	    .mobMenu .overclose { display:none; }
	    .mobMenu:target .overclose { display:block; }
	    
	    .mobMenu > .nav-mnu {
        	width: 100%; height:auto; 
        	position: relative; 
        	text-align:left; 
        }
        .mobMenu > .nav-mnu > a { margin: @m @m2; background-color:#fff; color:#f00; padding: @m @m4; display:block;
            &:hover, &:focus { background-color:#f00; color:#fff;  }
        } 

    // кнопка открывающая меню 
	    &> a.mob {
	        display:inline-block;
	        background: #900; width:1.8em; height:1.8em; border-radius:0.9em;
	        margin-top:1.1em; margin-right: 1em; padding:0; box-shadow: 0px 0px 3px #000; 
	       
	       position:absolute; right:0;
	        &:hover, &:focus { background: #f00; box-shadow: 0px 0px @m #000; }
	    }
	    
    }
    
	 nav .home::after { content: "На нлавную"; }
	 nav a .home::after { color:#f00; }
	 nav a:hover .home::after { color:#fff; }
	 
	 nav .admin::after { content: "Модераторский пульт"; }
	 nav a .admin::after { color:#f00; }
	 nav a:hover .admin::after { color:#fff; }
}


/* help  */.modalDialog {
	position: fixed;
	top: 0; right: 0; bottom: 0; left: 0; 
	z-index: 99999;
	-webkit-transition: opacity 4s ease-in;
	-moz-transition: opacity 4s ease-in;
	transition: opacity 4s ease-in;
	display: none;
	pointer-events: none;
}

.modalDialog:target { display: block; pointer-events: auto; }

.modalDialog > div {
	width: 280px;
	min-height: 100px;
	position: relative;
	margin: 10% auto;
	padding: @m2 @m4 @m4 100px;
	border-radius: @r;
	background: #fff;
	background: -moz-linear-gradient(#fff, #999);
	background: -webkit-linear-gradient(#fff, #999);
	background: -o-linear-gradient(#fff, #999);
	box-shadow: 0 0 @m2 rgba(0,0,0,0.75);
	text-align:left;
}
.modalDialog > div > h2 { margin: 5px 0;}
.modalDialog > div > p { margin: 5px 0; padding: 0; }
.modalDialog > div > .persona {
	width: 80px;
	height: 220px;
	position: absolute;
	border-radius: 10px; 
	content:"1";
	left:-5px; top:-30px;
	background-image: url('/theme/img/ppls.png');
	background-repeat:norepeat;
	background-position: -610px 0px;
}

.close {
	background-color: #606061;
	color: #FFFFFF!important; font-weight:700;
	text-align: center;
	line-height: 30px;
	position: absolute;
	right: -10px; top: -10px; width: 30px;
	text-decoration: none;
	font-weight: bold;
	border-radius: 15px;
	box-shadow: 1px 1px 3px #000;
	
    transition-property: background-color;
    transition-duration: 0.75s;
}

.close:hover, .close:focus { background-color: #ff0000; color:#fff!important;  text-decoration:none;}
.overclose {
	display: inline-block;
	background: rgba(0,0,0,0.85);
	position: absolute; 
	margin:0; padding:0;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0; 
	pointer: none;
	transition-property: background-color;
	transition-duration: 5s;
	
}

.overclose:hover, .overclose:focus { background: rgba(0,0,0,0.75)!important;  }