.text {
	font-size: 1em;
	list-style: none;
	padding:0; margin:0;
	background-color: @block-color; color: lighten(@color, 20%);
	
	h1,h2,h3,h4,h5,h6  { margin: 2em @m2 0.1em;  }
	.content > h1, .content > h2, .content > h3, .content > h4, .content > h5, .content > h6  { margin: 0 @m2 0.1em;  }
    p { padding: @m @m2; } 
    blockquote { font-style: italic; padding-left: @m2; border-left: @border-line; }
    a  { color: inherit; text-decoration: underline; }
    h2  {
        color: @Hx-color; text-align: left;
        display: block; w-idth: 100%;
        
        &> a  {
            color: inherit; text-decoration: underline;
            font-size: 0.75em; font-weight: normal;
            display: inline-block; margin-left: 0.5em;
        }
    }
    ul,ol {
        margin: @m2 @m8;
        text-align: left; display:block; 
         
        li {
            margin: @m 0; padding: @m 0;
            line-height: 1.2em;
            font-size: 0.9em;
	        .whr { padding-bottom: 0.5em; margin-bottom: 0.5em; border-bottom: 1px dotted #666; }
        }
    } 
    
    time {
    color: #666;
    display: block;
    font-size: 0.85em;
    line-height: 0.85em;
    }
    time:before {  content: attr(datetime); }
    time.fest, time.weekend { color:#f00; }
    
    table { border:3px solid #eee; padding:0.1em; margin: 1em 0; font-size:0.8em;  }
    tr { margin: 0.5em; }
    tr:nth-child(even) {background: #bbb; }
    //tr:nth-child(odd) {background: #FFF}
    td { margin: 0.5em; padding: 2px @m; }
		
    /* lead text*/
    .lead {
        margin: 0.5em 0;
        padding: 0.5em 1em;
        border-radius: @r;
        font-size:1.2em; line-height:1.4em;
        color:#666; background-color:#fff;
    }	
    
    section {
    	color:@color;
    	margin: @m 0 @m2; padding: 0.3em 0 0.7em 0; 
    	list-style: none;  
    	font-size: 1em; font-weight: normal;
    	display: flex; 
	    flex-direction: row;
        justify-content: space-between;
	    align-content: flex-start;
	    align-items: stretch;
	    flex-wrap: wrap; 
    }
	.content, .field { padding: @m4; }
	.content { flex: 3 1; }
    .field {
	    flex: 1 1;
	    text-align: center;
	    border-left: 2px dotted #bbb;
	    font-size:0.9em; 
	    
	    &> a.author {
	        display: block;
	        text-align: center;
	        box-sizing: border-box;
	        margin: 0 0 0 @m4;
	        top: 80px;
	        
            img { display: block; clear: both; margin: 0 auto; border-radius: 50%; }
	    }
    }
    
}