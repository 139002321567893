
.auth { 
	font-size: 1em; padding: @m4; margin: 0; 
	background-color: darken(@BG-color, 10%);
	color: lighten(@color, 40%);
	
	h2  { color:@Hx-color; }
	p  { margin: @m2 ; color: inherit; }
	label { display:block; color: black; }
	input, textarea {
	    display:block; box-sizing: border-box; max-width: 100%; width: 100%;
	    margin: @m 0 @m2; padding: 0 @m2;
	    font-size: 1.1em; line-height: 1.4em;
	}
	input[type='text'], input[type='password'], textarea { font-family: @font-family-add; }
	input[type=button], input[type=submit], input[type=reset] {
	    display: inline-block;
	    font-family: @font-family!important;
	    width: auto; padding: 0 @m2;
	}
	.lh { line-height: 2.4em; }
	.socpfroile {
	    input,label { display:inline-block; }
	    label { display:inline-block;
	        margin: @m @m2 @m2; width: 1.2em;
	        overflow:hidden;
	        white-space:nowrap; }
	    }
}