.news {
	display: flex; 
	flex-direction: row;
	align-content: flex-start;
	justify-content: center;
	align-items: flex-start;
	flex-wrap:wrap;
	font-size: 1em;
	list-style:none;
	padding:@m4; margin:0;
	border-radius:@r;
	background-color: lighten(@BG-color, 20%); color: darken(@color, 20%);
}
.news h2  { color: @Hx-color; text-align: left; display: block; width: 100%; }
.news h2 > a  { font-size: 0.75em; color: inherit; text-decoration: underline; font-weight: normal; display: inline-block; margin-left: 0.5em; }

.news li {
	font-size:0.7em;
	ba-ckground-color:#eee;
	color:#3a211b;
	// margin:5px 0 10px; padding: 1em 20px 1em 10px; border-radius:5px;
	margin: @m 0 @m2; padding: 0.3em 0 0.7em 0; 
	list-style:none; display:inline-block;  
	font-weight: normal;
	border-bottom: @border-line;
	width:100%;
}
.news li img { margin: 0 0.5em 0.5em 0.25em; float:left; width:100px; height:100px; }
.news li time  { color:#666; font-size: 0.85em; display:block; margin: 0 0 0.5em 120px; line-height:0.85em; }
.news li h6 { font-size:1em; color:#67554f; margin: 0 0 0.25em 120px; line-height:1.3em; text-transform:  uppercase; }
.news li p { font-size:1em; line-height:1.4em; color:inherit; margin: 0 0 0  120px; }
.news li a { font-size:1em;  color:#933; text-decoration: underline;}
.news li a:hover { color:#f33; }
.news li .more { margin: 1em  0 0 0;  display:inline-block; float:right; }