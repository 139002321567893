@charset "UTF-8";
// include outer CSS/LESS files Marck Script
//@import url("http://fonts.googleapis.com/css?family=Jura:400,600,500&subset=latin,cyrillic,cyrillic-ext");
@import url("http://fonts.googleapis.com/css?family=Marck+Script:400,600,500&subset=latin,cyrillic,cyrillic-ext");
//@import url("http://fonts.googleapis.com/css?family=PT+Sans+Narrow:400,700&subset=latin,cyrillic");
@import url("http://fonts.googleapis.com/css?family=PT+Serif:400,700&subset=latin,cyrillic");

@import "_reset.less"; 

@m: 5px;
@r: 0;
@color: #333;
@link-color: #33f;
@Hx-color: #633;
@BG-color: #ccc;
@panel-color: spin( @BG-color, -60);
@block-color: spin( @BG-color, 60);

@radius: @r * 2;
@r2: @r * 2;
@m2: @m * 2;
@m3: @m * 3;
@m4: @m * 4;
@m6: @m * 6;
@m8: @m * 8;

@border-line:  2px solid darken(@BG-color, 30%);

@font-base: 18px;
@font-family: Underdog, "PT Serif", serif; 
@font-family-add: "Marck Script", serif; 

html { height: 100%; }
body { margin: 0; display: flex; flex-direction: column; height: 100%; background-color:#555050; }
header { margin: 0 @m4 0; }
footer { margin: 0 @m4 0; }
nav { margin: 0 @m4; z-index:20; }
section { margin: 0 @m4; z-index:2; }
header, footer, nav, section.main-content  { font-size: @font-base; line-height: 1.4em; }
header, footer, nav, section.main-content { padding: 0; box-shadow: 0 3px @m rgba(0,0,0,0.5); }
h1, h2, h3, h4, h5, h6 { font-family: @font-family; line-height: 1.2em; margin: 0.5em 0 0.25em;}


h1 { font-size: 1.7em; }
h2 { font-size: 1.7em; }
h3 { font-size: 1.4em;  }
h4 { font-size: 1.4em; }
h5 { font-size: 1.2em; text-transform: uppercase; }
h6 { font-size: 1.2em; text-transform: uppercase; }


header {
	flex: 0 0 auto;
	border-radius: 0 0 @r2 @r2;
	background-color:#f00;
	background-image: url('/content/cover.jpg');
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center top;
	/* filter: blur(5px); */
	/*filter: saturate(15%) ; */
	
	font-weight: bold; color: white; text-shadow: 0px 2px 2px black, 0 0 0.2em #333;
	a { color: white!important; padding:@m2 @m4; }
}
section.main-content { flex: 1 0 auto; background-color:#bbb;  border-radius: @r2; }
footer{ flex: 0 0 auto; background-color:#eeb;  border-radius: @r2 @r2 0 0;   }

@media screen and (min-width: 1280px) {
	header, footer, nav, section.main-content  {
	    width: 1200px; margin-left: auto; margin-right: auto;
	    font-size: 1.2em;
	}
	header { height: 220px; }
	header::after { content:"1200"; }
	footer { height: 150px; }
	nav {  height: 2em; line-height: 1.5em; }
	section.main-content  { }
	* { color: #333; }
	
}
@media screen and (max-width: 1280px) {
	header, footer, nav, section.main-content  {
	    width: 960px; margin-left: auto; margin-right: auto;
	    font-size: 1.1em;
	}
	header { height: 220px; }
	header::after { content:"960"; }
	footer { height: 150px; }
	nav {  height: 2em; }
	section.main-content  { }
	* { color: #333; }
	
}
@media screen and (max-width: 980px) {
	header, footer, nav, section.main-content  {
	    width: 760px; margin-left: auto; margin-right: auto;
	    font-size: 1.1em;
	}
	header { height: 220px; }
	header::after { content:"740"; }
	footer { height: 150px; }
	nav {  height: 2em; }
	section.main-content  { }
	* { color: #633; }
	
}

/* экран меньше 760px - планшет */
@media screen and (max-width: 760px) {
	header, footer, nav, section.main-content  {
	    width: 620px; margin-left: auto; margin-right: auto;
	    font-size: 1em;
	}
	header { height: 200px; }
	header::after { content:"620"; }
	footer { height: 150px; }
	nav { line-height: 1.25em; }
	section.main-content  { }
	* { color: #933;  }
}
/* экран меньше 720px - планшет */
@media screen and (max-width: 720px) {
	header, footer, nav, section.main-content  {
	    width: 460px;
	    margin-left: auto; margin-right: auto;
	    font-size: 1.1em;
	}
	header { height: 180px; }
	header::after { content:"460"; }
	footer { height: 150px; }
	nav {  }
	section.main-content  { }
	* { color: #933;  }
}
/* экран меньше 480px - мобилка */
@media screen and (max-width: 480px) {
	html { -webkit-text-size-adjust: none; } 
	header, footer, nav, section.main-content  {
	    width: 320px; width: auto;
	    margin-left: auto; margin-right: auto;
	    margin-left: 0; margin-right: 0;
	    border-radius: 0; font-size: 1.1em;
	}
	header { height: 180px; }
//	header::after { content:"320"; }
	footer { height: 150px; }
	
	nav { }
	section.main-content  { }
	* { color: #966; }
}

/* каверы разделов */

.cover { 
	padding: 4em 1em 0.5em; 
	font-size: 2em; 
	text-shadow: 0px 2px 2px black, 0 0 0.2em #333;
	text-align: left; color: #fff;
	border-radius: @r;
	background-color:#f00;
	background-image: url('/content/cover.jpg'); 
	background-attachment: fixed;
	background-position: center top;
	/* filter: blur(5px); */
	 filter: saturate(15%); 
	 * { color:#fff; }
} 
.cover:hover { filter: saturate(100%); }
.girls { background-image: url('/content/cover-girls.jpg');  }
.party { background-image: url('/content/cover-party.jpg'); }
.money { background-image: url('/content/cover-money.jpg');}
.secret { background-image: url('/content/cover-secret.jpg');}
.wtcc { background-image: url('/content/cover-wtcc.jpg');}
.drakon7 { background-image: url('/content/cover-drakon7.jpg');}
.hooli-drift { background-image: url('/content/cover-hd1.jpg');}


/* для экранов меньше 720 располагаем блоки горизонтально  */
@media (max-width: 720px){
	.cover { background-attachment: scroll; background-size: auto 300px; }
}
	

/* это только цвет 
.blue { order: 3; background-color:#aaf; }
.red { order: 1; background-color:#faa; }
.green { order: 2; background-color:#afa; }
*/

.container {
	padding:0; 
	display: flex;
    flex-direction: row;	
    align-items: stretch;	
}
/* для экранов меньше 720 располагаем блоки горизонтально  */
@media (max-width: 720px){
	.container { flex-direction: column; }
	.container > * { padding-left: 20px!important; padding-right:20px!important; }
}

.col-1 { flex: 1; }
.col-2 { flex: 2; }
.col-f { flex: 2; }

/* контейнер для итемов  */
 .items {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	flex-wrap:wrap;
	font-size: 12px;
	padding:0; margin:0; list-style:none;
	
	li {
	   display: inline-block; max-width: 70px; max-height: 140px; min-height: 70px;
	   margin:@m;  padding: @m @m3; border-radius: @r;
	   background-color:#333; color:#eee; list-style: none;
	   overflow: hidden;
	} 
}

/* стики */

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}
.sticky:before,
.sticky:after {
    content: '';
    display: table;
}

time:after { content: attr(data-time); color: red; }
.rgt { float:right; }
.lft { float:left; }

@import "news.less"; 
@import "auth.less"; 
@import "activity.less"; 
@import "text.less"; 

@import "_alert.less"; 
@import "_message.less"; 
