
.activity {  
	padding: @m4; margin: 0; border-radius: @r;
	background-color: lighten(@BG-color, 30%);
	font-size: 1em; color: #336;
	
	h2  { color:@Hx-color; 
	    &> a  { font-size: 0.6em; color: inherit; text-decoration: underline; font-weight: normal; }
    }
	&> div {
    	flex-wrap: wrap;
    	display: flex; 
    	align-items: stretch;
    	font-size: inherit!important;
    	margin-left: -1 * @m; margin-right: -1 * @m;
	
	 * { font-size: inherit; font-weight: inherit; }
	}
	
	.collect {
    	flex: 1;
    	border-width: 0; border-bottom-width: 0.5em; border-style: solid;
    
    	margin: @m2; padding: 0 0 @m2;
    
    	background-color:#fff;  
    	background-size: auto 270px; 
    	background-position: center top;
    	background-repeat: no-repeat;
    	
    	/* filter: blur(5px); */
    	filter: saturate(5%); 
    	box-shadow: 0 0 @m2 rgba(0,0,0,0.25); 
    	transition: 1s filter cubic-bezier(0.1, -0.6, 0.2, 0);
	
	    &:hover { filter: saturate(100%); }
	    
	    h3  {
            display: inline-block;
            padding: 0.15em 1em 0.25em @m4; margin: 4em 0 1em;
            color: lighten(@Hx-color, 10%); background-color: rgba(255,255,255,0.9);  font-weight: bold;
	    } 
	    
	    ul  {
        	font-size: 0.75em; 
        	height: 50%; width: 100%;
        	margin: @m2 0; padding: @m6 0;
        	background-color:#fff;  
        	vertical-align: top;
        	list-style-position: outside;
        	font-weight: normal;
        }
        li {
        	margin: 0 1em 0 1.6em; padding: 0;
        	font-size: 1em; line-height:1.2em;  text-decoration: none;
        	color: @color; 
        }
        li > a {
            display: inline-block;
            padding: 0.25em 10px; margin: -0.2em 0 0.3em 0;
            color: inherit; vertical-align: top; 
            transition: 1s filter cubic-bezier(0.1, -0.6, 0.2, 0);
        
            &:hover {color: red; text-decoration: underline; background-color:#ee3; }
        }
    }
} 

.childrens { color:#f33; border-color:#633; background-color:#633; background-image: url('/content/cover-girls.jpg');  }
.faith { color:#393; border-color:#363;  background-image: url('/content/cover-party.jpg'); } 
.othe { color:#33f; border-color:#336;  background-image: url('/content/cover-secret.jpg'); }

 
@media (max-width: 720px){
	.activity > div { flex-direction: column; }
	.collect ul  { height:auto; font-size:1em; } 
}
 
@media (max-width: 720px){
	.cover { background-attachment: scroll; background-size: auto 400px; }
}
