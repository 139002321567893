article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, footer { display: block; }
html, body, div, span, h1, h2, h3, h4, h5, h6, p, em, img, strong, sub, sup, b, u, i,  dl, dt, dd, ol, ul, li, fieldset, form, label, table, tbody, tfoot, thead, tr, th, td,
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
    margin: 0;
    padding: 0;
	border: 0;
	outline: 0;                     /* обнуляем отступы и убираем рамки */
	vertical-align: baseline;       /* приводим все к одной базовой линии */
	background: transparent;        /* чтобы не проскакивали левые фоны, установленные по умолчанию */
	font-size: 100%;                /* размер шрифта всем одинаковый */
}
html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    overflow-y: scroll;              /* всегда показываем вертикальную полосу прокрутки  */
}

body {
    line-height: 1;
	background-color: @BG-color;
	color: @color;
	text-align: left;
	font-size: @font-base;
    font-family:@font-family;
}
/* links */
/* порядок правил для ссылок имеет значение */
a {
    margin: 0;
	padding: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
a:focus {
  outline: thin dotted #333333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
a:hover, a:active { outline: 0; }

a, a:visited { 
    color: @link-color;
    text-decoration: none;
}
a:hover {
	color: @link-color;
    text-decoration: underline;
}
a:focus, a:active {
	color: @link-color;
	text-decoration: underline;
}


/* text */
sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sup { top: -0.5em; }
sub { bottom: -0.25em; }


/* forms */

input, select, button, textarea {  }

button,
input,
select,
textarea {
    margin: 0;                      /* убираем отступы по умолчанию для элементов форм (в частности для checkbox и radio) */
    font-size: 100%;                /* делаем размер шрифтов везде одинаковым */
    vertical-align: middle;
    font-family:@font-family;       /* чтобы шрифт был такой же как и везде */
}
button, 
input {
  *overflow: visible;
  line-height: normal;
}
button::-moz-focus-inner,
input::-moz-focus-inner { padding: 0; border: 0; }

/* курсор в виде руки для всех кликабельных элементов форм */
label,
button,
input[type="button"],
input[type="reset"],
input[type="submit"] { cursor: pointer; -web-kit-appearance: button; }

input[type="search"] {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-appearance: textfield;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {  -webkit-appearance: none; }

/* убираем внутренние отступы для текстовых полей */
input[type="text"],
input[type="password"],
textarea { padding: 0; }

/* вертикальное выравнивание чекбоксов и радиобатонов относительно меток */
input[type="checkbox"] { vertical-align: bottom; }
input[type="radio"] { vertical-align: text-bottom; }
textarea { overflow: auto; vertical-align: top; }

/* tables */
/* устраняем отступы между ячейками таблицы */
table { border-collapse: collapse; border-spacing: 0; }
/* возвращаем привычное вертикальное выравнивание */
td, td img { vertical-align: top; } 


/* images */
img {
  // Responsive images (ensure images don't scale beyond their parents) 
  max-width: 100%;      // Part 1: Set a maxium relative to the parent
  width: auto\9;        // IE7-8 need help adjusting responsive images
  height: auto;         // Part 2: Scale the height according to the width, otherwise you get stretching
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic;
}
#map_canvas img { max-width: none; }
/* --------------- /reset.css --------------- */
